define("discourse/plugins/chat/discourse/components/chat-thread-title-prompt", ["exports", "@glimmer/component", "@ember/object", "@ember/runloop", "@ember/service", "I18n", "discourse/plugins/chat/discourse/components/chat/modal/thread-settings", "discourse/plugins/chat/discourse/lib/chat-constants", "discourse/plugins/chat/discourse/models/user-chat-thread-membership"], function (_exports, _component, _object, _runloop, _service, _I18n, _threadSettings, _chatConstants, _userChatThreadMembership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ShowThreadTitlePrompt = _exports.default = (_class = class ShowThreadTitlePrompt extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatApi", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _initializerDefineProperty(this, "toasts", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "site", _descriptor5, this);
      _defineProperty(this, "toastText", {
        title: _I18n.default.t("chat.thread_title_toast.title"),
        message: _I18n.default.t("chat.thread_title_toast.message"),
        dismissLabel: _I18n.default.t("chat.thread_title_toast.dismiss_action"),
        primaryLabel: _I18n.default.t("chat.thread_title_toast.primary_action")
      });
      (0, _runloop.next)(() => {
        if (this.canShowToast) {
          this.show();
          this.updateThreadTitlePrompt();
        }
      });
    }
    get membership() {
      return this.args.thread.currentUserMembership;
    }
    async updateThreadTitlePrompt() {
      try {
        const result = await this.chatApi.updateCurrentUserThreadTitlePrompt(this.args.thread.channel.id, this.args.thread.id);
        this.args.thread.currentUserMembership = _userChatThreadMembership.default.create(result.membership);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Couldn't save thread title prompt status", e);
        if (this.membership) {
          this.membership.threadTitlePromptSeen = false;
        }
      }
    }
    disableFutureThreadTitlePrompts() {
      this.currentUser.set("user_option.show_thread_title_prompts", false);
      this.currentUser.save();
    }
    get canShowToast() {
      if (this.site.desktopView || this.args.thread.user_id !== this.currentUser.id && !this.currentUser.admin) {
        return false;
      }
      const titleNotSet = this.args.thread.title === null;
      const hasReplies = this.args.thread.replyCount >= _chatConstants.THREAD_TITLE_PROMPT_THRESHOLD;
      const showPrompts = this.currentUser.user_option.show_thread_title_prompts;
      const promptNotSeen = !this.membership?.threadTitlePromptSeen;
      return titleNotSet && hasReplies && showPrompts && promptNotSeen;
    }
    show() {
      this.toasts.default({
        duration: 5000,
        showProgressBar: true,
        class: "thread-toast",
        data: {
          title: this.toastText.title,
          message: this.toastText.message,
          actions: [{
            label: this.toastText.dismissLabel,
            class: "btn-link toast-hide",
            action: toast => {
              this.disableFutureThreadTitlePrompts();
              toast.close();
            }
          }, {
            label: this.toastText.primaryLabel,
            class: "btn-primary toast-action",
            action: toast => {
              this.modal.show(_threadSettings.default, {
                model: this.args.thread
              });
              toast.close();
            }
          }]
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toasts", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateThreadTitlePrompt", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateThreadTitlePrompt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableFutureThreadTitlePrompts", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "disableFutureThreadTitlePrompts"), _class.prototype)), _class);
});